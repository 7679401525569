<template>
  <nav>
    <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        width="320"
        right
      >
        <v-list dense>
          <v-list-item
          v-for="menuItem in menuItems"
          :key="menuItem.id"
          link
          :to="{ path: menuItem.link }"
        >
          <v-list-item-content :class="($vuetify.lang.current === 'he') ? 'text-right' : 'text-left'">
            <v-list-item-title class="text-subtitle-1" v-if="$vuetify.lang.current === 'he'">{{ menuItem.title }}</v-list-item-title>
             <v-list-item-title class="text-subtitle-1" v-else>{{ menuItem.titleEn }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item >
        <v-list-item @click="changeLang">
         <v-list-item-content :class="($vuetify.lang.current === 'he') ? 'text-right' : 'text-left'">
            <v-list-item-title class="text-subtitle-1" v-if="$vuetify.lang.current === 'he'">English</v-list-item-title>
            <v-list-item-title class="text-subtitle-1" v-else>עברית</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-list>
        <template v-slot:append>
           <v-list
          nav
          dense
        >
          <v-list-item
            link
            @click="drawer = false"
          >
            <v-list-item-title class="subtitle-1 white--text font-weight-bold" v-if="$vuetify.lang.current === 'he'">סגור</v-list-item-title>
            <v-list-item-title class="subtitle-1 white--text font-weight-bold" v-else>Close</v-list-item-title>
            <v-list-item-action>
              <v-icon>close</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
      </v-navigation-drawer>
     <v-app-bar
     :clipped-right="$vuetify.breakpoint.lgAndUp"
      app
      dense
      flat
      id="home"
      class="text-center pt-1"
    >
      <v-app-bar-nav-icon v-if="isMobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer v-if="isMobile"></v-spacer>
      <router-link to="/">
        <v-img
        v-if="isMobile"
        :src="require('../assets/yoel.svg')"
        alt="Yoel Harel :: יואל הראל"
        max-height="70"
        max-width="180"
        contain
        style="cursor: pointer;"
      ></v-img>
      </router-link>
      <!-- <v-spacer v-if="isMobile"></v-spacer> -->
      <template v-if="!isMobile">
      <v-spacer></v-spacer>
      <v-btn :to="{ path: '/' }" text class="ml-1 mr-2" v-if="$vuetify.lang.current === 'he'">
      ראשי
      </v-btn>
      <v-btn :to="{ path: '/' }" text class="ml-1 mr-2" v-else>
      Home
      </v-btn>
      <v-btn :to="{ path: '/albums' }" text class="mx-1" v-if="$vuetify.lang.current === 'he'">
      גלריות
      </v-btn>
      <v-btn :to="{ path: '/albums' }" text class="mx-1" v-else>
      Gallery
      </v-btn>
      <v-btn :to="{ path: '/video' }" text class="mx-1" v-if="$vuetify.lang.current === 'he'">
      סרטונים
      </v-btn>
      <v-btn :to="{ path: '/video' }" text class="mx-1" v-else>
      Video
      </v-btn>
      <v-btn :to="{ path: '/about' }" text class="mx-1" v-if="$vuetify.lang.current === 'he'">
      אודות
      </v-btn>
      <v-btn :to="{ path: '/about' }" text class="mx-1" v-else>
      About
      </v-btn>
      <v-btn :to="{ path: '/contact' }" text class="mx-1" v-if="$vuetify.lang.current === 'he'">
      צור קשר
      </v-btn>
      <v-btn :to="{ path: '/contact' }" text class="mx-1" v-else>
      Contact
      </v-btn>
      <v-btn @click="changeLang" text class="mx-1" v-if="$vuetify.lang.current === 'he'">
      English
      </v-btn>
      <v-btn @click="changeLang" text class="mx-1" v-else>
      עברית
      </v-btn>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <router-link to="/">
      <v-img
        v-if="!isMobile"
        :src="require('../assets/yoel.svg')"
        alt="Yoel Harel :: יואל הראל"
        max-height="70"
        max-width="180"
        contain
        style="cursor: pointer;"
      ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      </template>
    </v-app-bar>
  </nav>
</template>

<script>

import { bus } from '../main'
// import Pusher from 'pusher-js' // import Pusher
// const audioGetMessage = new Audio(require('@/assets/sound/1.mp3'))

export default {
  name: 'Navbar',
  data () {
    return {
      menuItems: [
        { id: 1, title: 'ראשי', titleEn: 'Home', link: '/' },
        { id: 2, title: 'גלריות', titleEn: 'Gallery', link: '/albums' },
        { id: 3, title: 'סרטונים', titleEn: 'video', link: '/video' },
        { id: 4, title: 'אודות', titleEn: 'About', link: '/about' },
        { id: 5, title: 'צור קשר', titleEn: 'Contact', link: '/contact' }
      ],
      fab: false,
      isMobile: false,
      drawer: false
    }
  },
  created () {
    this.drawer = false
    bus.$on('Open_isMobile', (data) => {
      this.isMobile = data
    })
  },
  methods: {
    changeLang () {
      if (this.$vuetify.lang.current === 'he') {
        let LangsSet = { 'Lang': 'en', 'LangDirection': false }
        window.axios.defaults.headers.common['set-lang'] = 'en'
        window.$cookies.set('CurrentLang', LangsSet)
        this.$vuetify.rtl = false
        this.$vuetify.lang.current = 'en'
      } else {
        let LangsSet = { 'Lang': 'he', 'LangDirection': true }
        window.axios.defaults.headers.common['set-lang'] = 'he'
        window.$cookies.set('CurrentLang', LangsSet)
        this.$vuetify.rtl = true
        this.$vuetify.lang.current = 'he'
      }
      bus.$emit('Open_album')
      bus.$emit('Open_gallery')
      bus.$emit('Open_video')
    }
  }
}
</script>

<style scoped>
.activeStyle {
  color: #2196F3!important;
}
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 0px!important;
}
</style>
