<template>
  <div>
    <v-row>
    <v-col
     cols="12"
     sm="9"
    >
    <span v-html="content" v-if="$vuetify.lang.current === 'he'"></span>
    <span v-html="content_en" v-else></span>
    </v-col>
     <v-col
     cols="12"
     sm="3"
    >
     <v-row>
    <v-col
      v-for="(image, ImageIndex) in images"
      :key="ImageIndex"
      class="d-flex child-flex"
      cols="12"
    >
      <v-img
        :src="image"
        :lazy-src="image"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
    </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from './../main'

// @ is an alias to /src
export default {
  data () {
    return {
      Dob: window.moment().format('DD/MM/YYYY'),
      Times: window.moment().format('H:mm'),
      menu: false,
      content: null,
      title: 'טוען',
      content_en: null,
      title_en: 'Loading',
      images: [],
      valid: true,
      showErr: false,
      errTexts: [],
      requiredRules: [
        v => !!v || 'שדה חובה'
      ],
      requiredRulesEmpty: [],
      usernameRules: [
        v => !!v || 'שדה חובה',
        v => /.+@.+\..+/.test(v) || 'יש להקליד דואר אלקטרוני תקין'
      ]
    }
  },
  created () {
    this.GetDashboard()
  },
  methods: {
    GetDashboard () {
      window.Store.commit('Loader', true)
      let vm = this
      window.axios.get(window.API.hometext)
        .then(response => {
          vm.ShowLightBox = true
          vm.title = response.data[0].title
          vm.content = response.data[0].content
          vm.title_en = response.data[0].title_en
          vm.content_en = response.data[0].content_en
          vm.images = response.data[0].images
          window.Store.commit('Loader', false)
        })
        .catch(error => {
          window.Store.commit('Loader', false)
          let errorMessage = error.response.data
          bus.$emit('Open_snackbar', 'error', errorMessage.err)
        })
    }
  }
}
</script>

<style>
.breadcrumbs a {
    color: #ff5722!important;
}
</style>
