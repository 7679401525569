class API {
    /// / התחברות למערכת
    static home = 'home.php'
    static albums = 'albums.php'
    static gallery = 'gallery.php'
    static addview = 'addview.php'
    static video = 'video.php'
    static about = 'about.php'
    static hometext = 'hometext.php'
    static contact = 'contact.php'
}

export default API
