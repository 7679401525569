import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      publicPath: '/',
      name: 'home',
      meta: {
        subname: 'home',
        class: 'highlighted',
        menuShow: true,
        requiresGuest: true
      },
      component: Home
    },
    {
      path: '/albums',
      publicPath: '/',
      name: 'albums',
      meta: {
        subname: 'albums',
        class: 'highlighted',
        menuShow: true,
        requiresGuest: true
      },
      component: () => import(/* webpackChunkName: "albums" */ './views/albums.vue')
    },
    {
      path: '/gallery/:id',
      props: true,
      publicPath: '/',
      name: 'gallery',
      meta: {
        subname: 'gallery',
        class: 'highlighted',
        menuShow: true,
        requiresGuest: true
      },
      component: () => import(/* webpackChunkName: "gallery" */ './views/gallery.vue')
    },
    {
      path: '/video',
      publicPath: '/',
      name: 'video',
      meta: {
        subname: 'video',
        class: 'highlighted',
        menuShow: true,
        requiresGuest: true
      },
      component: () => import(/* webpackChunkName: "video" */ './views/video.vue')
    },
    {
      path: '/about',
      props: true,
      publicPath: '/',
      name: 'about',
      meta: {
        subname: 'about',
        class: 'highlighted',
        menuShow: true,
        requiresGuest: true
      },
      component: () => import(/* webpackChunkName: "about" */ './views/about.vue')
    },
    {
      path: '/contact',
      props: true,
      publicPath: '/',
      name: 'contact',
      meta: {
        subname: 'contact',
        class: 'highlighted',
        menuShow: true,
        requiresGuest: true
      },
      component: () => import(/* webpackChunkName: "contact" */ './views/contact.vue')
    },
    {
      path: '*',
      publicPath: '/',
      name: '404',
      meta: {
        subname: '404',
        class: 'highlighted',
        menuShow: true,
        requiresGuest: true
      },
      component: Home
    }
  ]
})

// // Nav Guard
// router.beforeEach((to, from, next) => {
//   // Check for requiresAuth guard
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // Check if NO logged user
//     if (!window.Store.getters.getAccessToken) {
//       // Go to login
//       next({
//         path: '/home'
//         // query: {
//         //   redirect: to.fullPath
//         // }
//       })
//     } else {
//       // Proceed to route
//       next()
//     }
//   } else if (to.matched.some(record => record.meta.requiresGuest)) {
//     // Check if NO logged user
//     if (window.Store.getters.getAccessToken) {
//       // Go to login
//       next({
//         path: '/'
//         // query: {
//         //   redirect: to.fullPath
//         // }
//       })
//     } else {
//       // Proceed to route
//       next()
//     }
//   } else {
//     // Proceed to route
//     next()
//   }
// })

export default router
